import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { createStyles, StyleRules } from '@material-ui/styles';
import { Link } from 'gatsby';
import i18n from 'i18next';

import { login } from '../../actions/userActions';
import LoadingLogo from '../../components/LoadingLogo';
import TextField from '../../components/TextField';
import { AppState } from '../../reducers';
import { hideNotificationAction } from '../../reducers/notificationReducer';
import { FUserTypeEnum } from '../../reducers/userReducer';
import { EMAIL_VALIDATION_REGEX, PHONE_VALIDATION_REGEX } from '../../utils/constant';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    center: {
      textAlign: 'center',
    },
    formContainer: {
      margin: 'auto',
      width: 396,
      marginTop: theme.spacing(2),
      padding: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
    },

    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(1.5, 0, 2),
    },
    resetPassword: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
    },
    formFooter: {
      margin: 'auto',
      marginTop: 16,
    },
  });

interface LoginState {
  username?: string;
  password?: string;
}

const LoginView = ({ classes, userType }: Props): React.ReactElement => {
  const { t } = useTranslation();
  i18n.loadNamespaces('Login');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hideNotificationAction());
  }, []);

  const isLoading = useSelector((state: AppState) => state.loading.isLoading);

  const [username, setUsername] = React.useState<string>('');
  const [usernameHelperText, setUsernameHelperText] = React.useState<string>();
  const [usernameError, setUsernameError] = React.useState<boolean>(false);
  const [password, setPassword] = React.useState<string>('');
  const [passwordHelperText, setPasswordHelperText] = React.useState<string>();
  const [passwordError, setPasswordError] = React.useState<boolean>(false);
  const [button, setButton] = React.useState<boolean>(true);

  const handleChange = (prop: keyof LoginState) => (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const targetValue = event.target.value;
    prop === 'username' && setUsername(targetValue);
    /password/.test(prop) && setPassword(targetValue);
  };

  const validateUsername = (): boolean => {
    setUsernameHelperText('');
    setUsernameError(false);
    if (!EMAIL_VALIDATION_REGEX.test(username) && !PHONE_VALIDATION_REGEX.test(username)) {
      setUsernameHelperText(t('login:incorrect_email_phone_format'));
      setUsernameError(true);
      return false;
    }
    return true;
  };

  const validatePassword = (): boolean => {
    setPasswordHelperText('');
    setPasswordError(false);
    if (!password) {
      setPasswordHelperText(t('login:enter_password'));
      setPasswordError(true);
      return false;
    }
    return true;
  };

  const handleLogin = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();
    if (validateUsername() && validatePassword())
      await dispatch(login(username, password, userType));
  };

  useEffect(() => {
    if (username == '') {
      setButton(true);
    }
    if (password == '') {
      setButton(true);
    }
    // If yes, activate the button
    else {
      if (validateUsername() && validatePassword()) setButton(false);
      else setButton(true);
    }
  });

  if (isLoading) {
    return <LoadingLogo />;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.formContainer}>
        <form className={classes.form} noValidate>
          <TextField
            label={t('login:account_number')}
            error={usernameError}
            helperText={usernameHelperText}
            name="username"
            value={username}
            onChange={handleChange('username')}
            onBlur={validateUsername}
            autoFocus={false}
          />
          <TextField
            error={passwordError}
            helperText={passwordHelperText}
            name="password"
            value={password}
            label={t('login:password')}
            type="password"
            onChange={handleChange('password')}
            onBlur={validatePassword}
            autoFocus={false}
          />
          <Grid container>
            <Grid item className={classes.resetPassword}>
              <Link to="/resetpassword" color="secondary">
                {t('login:forgot_password')}
              </Link>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleLogin}
            disabled={button}
          >
            {t('login:logon')}
          </Button>
          <Grid container>
            <Grid item className={classes.formFooter}>
              <span> {t('login:no_account')}</span>
              <Link to={`/consultant/register`} color="secondary">
                <Button variant="contained" color="primary" size="small">
                  {t('login:register_new')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </form>
        <div className={classes.center}>测试版若遇技术问题请联系（contact@fintegrity.cn ）</div>
      </Paper>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  userType: FUserTypeEnum;
}

export default withStyles(styles)(LoginView);
